import { defaultWindow } from "@vueuse/core";
import AOS from "aos";
import "aos/dist/aos.css";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.AOS = AOS.init({
    // disable: window.innerWidth < 640,
    duration: 600,
    easing: "ease-in-out-cubic",
    once: true,
  });
});
