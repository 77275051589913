export default defineAppConfig({
  ui: {
    container: {
      constrained: "max-w-[1536px]",
    },
    primary: "turquoise",
    gray: "slate",
    button: {
      default: {
        variant: "outline",
        size: "md",
      },
    },
    input: {
      padding: {
        sm: "px-3 py-2.5",
      },
    },
    avatar: {
      background: "bg-gray-300 dark:bg-gray-800",
    },
    badge: {
      base: "inline-flex items-center",
      rounded: "rounded-md",
      font: "font-medium",
      size: {
        xs: "text-xs px-1.5 py-0.5",
        sm: "text-xs px-2 py-1",
        md: "text-sm px-2 py-1",
        lg: "text-sm px-2.5 py-1.5",
      },
      color: {
        white: {
          solid:
            "ring-1 ring-inset ring-gray-300 dark:ring-gray-700 text-gray-900 dark:text-white bg-white dark:bg-gray-900",
        },
        gray: {
          solid:
            "ring-1 ring-inset ring-gray-300 dark:ring-gray-700 text-gray-700 dark:text-gray-200 bg-gray-50 dark:bg-gray-800",
        },
        black: {
          solid: "text-white dark:text-gray-900 bg-gray-900 dark:bg-white",
        },
      },
      variant: {
        solid:
          "bg-{color}-500 dark:bg-{color}-400 text-white dark:text-gray-900",
        outline:
          "text-{color}-500 dark:text-{color}-400 ring-1 ring-inset ring-{color}-500 dark:ring-{color}-400",
        soft: "bg-{color}-50 dark:bg-{color}-400 dark:bg-opacity-10 text-{color}-500 dark:text-{color}-400",
        subtle:
          "bg-{color}-50 dark:bg-{color}-400 dark:bg-opacity-10 text-{color}-500 dark:text-{color}-400 ring-1 ring-inset ring-{color}-500 dark:ring-{color}-400 ring-opacity-25 dark:ring-opacity-25",
      },
      default: {
        size: "sm",
        variant: "solid",
        color: "primary",
      },
    },
    icons: {
      // dynamic: false
    },
    accordion: {
      item: {
        padding: 'py-3 px-4 sm:px-6'
      }
    }
  },
  vercelAnalytics: {
    mode: "auto",
  }
});
