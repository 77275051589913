import { useCompanyStore } from "~/stores/companyStore";
import type { ICompanyInfo } from "~/types";
export default defineNuxtPlugin((nuxtApp) => {
  const info = ref({} as ICompanyInfo);

  async function getCompanyInfo() {
    const config = useRuntimeConfig();
    const data = (await $fetch(
      `${config.public.medusaBackendUrl}/cybandy/store_/shop`,
    )) as ICompanyInfo;
    info.value = data;
  }

  nuxtApp.hook("app:beforeMount", () => {
    useAsyncData(async () => {
      await Promise.all([useCompanyStore().get()]);
    });
  });

  return {
    provide: {
      companyInfo: {
        address: computed(() => info.value.address),
        social: computed(() => info.value.social_media),
        name: computed(() => info.value.name),
        tnc: computed(() => info.value.tnc),
        privacy_policy: computed(() => info.value.privacy_policy),
      },
    },
  };
});
