import type { Region, Country } from "@medusajs/medusa";

export const useRegionStore = defineStore("regionStore", {
  state: () => ({
    activeRegion: {} as Region,
    regionList: [] as Region[],
    activeCountry: {} as Country,
    countryList: [] as Country[],
  }),
  persist: {
    paths: ["activeCountry", "countryList", "activeRegion"],
    storage: persistedState.localStorage,
  },
  getters: {
    currencyCode: (state) => state.activeRegion.currency_code,
    flag: (state) => `https://flagcdn.com/${state.activeCountry.iso_2}.svg`,
  },
  actions: {
    async getCountries() {
      const { regions: _reg } = await useCybandyClient().regions.list();
      if (_reg) {
        this.countryList = _reg.flatMap((x) => x.countries)
        // console.log(_reg)
        this.regionList = _reg
        if (Object.values(this.activeCountry).length == 0) {
          this.activeCountry = _reg[0].countries[0]
          this.activeRegion = _reg[0]
        }
      }
    },
    async chooseCountry(iso_2: string) {
      const _ind_country = this.countryList.findIndex((x) => x.iso_2 == iso_2);
      if (_ind_country) {
        this.activeCountry = this.countryList[_ind_country];
        this.activeRegion = this.regionList.filter(x => x.id == this.activeCountry.region_id)[0]
      }
      // update cart and products
    },
  },
});
