import { defineStore } from "pinia";

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    bazariToken: "",
  }),
  persist: true,
  getters: {
    getToken: (state) => state.bazariToken,
  },
  actions: {
    setToken(_token: string) {
      this.bazariToken = _token;
    },
    async login(email: string, password: string) {
      try {
        const { access_token } = await useCybandyClient().auth.getToken({
          email,
          password,
        });
        if (!access_token) {
          throw new Error("invalid credentials. Try again");
        }
        this.bazariToken = access_token;


        try {
          // get customer data
          await useCustomerStore().getCustomer();

          // check temp checkout data
          await useCheckoutStore().initTempData();
        } catch (error) {

        }


        return {
          status: true,
          message: "",
        };
      } catch (error: any) {
        // // console.log(error);

        return {
          status: false,
          message: error.message,
        };
      }
    },
    async logout() {
      try {
        await useCybandyClient().auth.deleteSession();
        this.bazariToken = "";
        const customer = useCustomerStore();
        customer.$reset();
        return {
          status: true,
          message: "",
        };
      } catch (error: any) {
        return { status: false, message: error.message };
      }
    },
  },
});
