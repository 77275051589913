export default defineNuxtPlugin((nuxtApp) => {
  const isScroll = ref(false);
  const bazari_public_images =
    "https://bazari-images.fra1.cdn.digitaloceanspaces.com/"; // digital_ocean spaces
  return {
    provide: {
      utilities: {
        modal: {
          login_registerModal: ref(false),
          mobile_slideOver: ref(false),
          errorAlert: ref(false),
          search: ref(false)
        },
        error: {
          title: ref("Oops!"),
          description:
            "Unexpected error occurred. Refresh the page or contact support",
        },
        isScroll: computed({
          set: (val) => (isScroll.value = val),
          get: () => isScroll.value,
        }),
        bazari_public_images,
      },
    },
  };
});
