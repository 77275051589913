import { defineStore } from "pinia";

export const useFavouriteStore = defineStore("favStore", {
  state: () => ({
    favourites: [] as { id: string; product_id: string }[],
  }),
  persist: true,
  getters: {
    all_products: (state) => state.favourites.map((x) => x.product_id),
  },
  actions: {
    async fetchUserFav() {
      try {
        const customer = useCustomerStore().customer;
        const _res = await useCybandyClient().customMethods.favourite.list({
          customer_id: customer.id,
          field: "id,product_id",
        });
        if (_res.favourite.length > 0) {
          this.favourites = _res.favourite;
        }
      } catch (error) {}
    },
    liked(product_id: string, id: string) {
      this.favourites.push({ product_id, id });
    },
    disliked(id: string) {
      this.favourites = this.favourites.filter((x) => x.id != id);
    },
  },
});
