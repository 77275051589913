import type { Cart, LineItem, StorePostCartReq } from "@medusajs/medusa";
import { groupBy } from 'lodash-es'

export const useCartStore = defineStore("cartStore", {
  state: () => ({
    cart: {} as Cart,
    cart_id: "",
    tempData: {} as StorePostCartReq,
    toBeDelItems: [] as string[],
  }),
  persist: {
    storage: persistedState.sessionStorage,
    paths: ["tempData", "toBeDelItems"],
  },
  getters: {
    num_items: (state) => state.cart?.items?.length ?? 0,
    uniqueProducts: (state) => {
      const res: { [key: string]: LineItem[] } = {};
      state.cart.items.map((_item) => {
        const _id = _item.variant.product_id as string;

        if (_id in res) {
          res[_id].push(_item as any);
        } else {
          res[_id] = [_item as any];
        }
      });

      return Object.values(res);
    },
    grouped: (state) => groupBy(state.cart.items, item => item.variant.product_id)
  },
  actions: {
    async getCart(id?: string) {
      if (id) {
        this.cart_id = id;
      }
      const { cart: _cart } = await useCybandyClient().carts.retrieve(
        this.cart_id,
      );

      this.cart = _cart;

      // anytime  we get a cart, we must check if there are any temporal cart
      // data we must add
      await this.resumeCart();
    },

    async addToCart(payload: StorePostCartReq) {
      payload.region_id = useRegionStore().activeRegion.id;
      if (!useCustomerStore().isLoggedIn) {
        this.tempData = payload;
        return navigateTo("/auth");
      }

      if (payload.items) {
        if (payload.items.length > 0) {
          const data = await Promise.all(
            payload.items?.map((x) => {
              return useCybandyClient().carts.lineItems.create(this.cart.id, x);
            }),
          );
        }
      }

      await this.getCart();

      return true;
    },

    async removeItem(id: string) {
      const { cart: _cart } = await useCybandyClient().carts.lineItems.delete(
        this.cart.id,
        id,
      );
      if (_cart) {
        this.cart = _cart;
      }
    },

    async updateItem(id: string, qty: number) {
      const { cart: _cart } = await useCybandyClient().carts.lineItems.update(
        this.cart.id,
        id,
        { quantity: qty },
      );
      if (_cart) {
        this.cart = _cart;
      }
    },

    async deleteAllItem() {
      await Promise.all(
        this.cart.items.map((x) =>
          useCybandyClient().carts.lineItems.delete(this.cart.id, x.id),
        ),
      );
      await this.getCart();
    },

    async resumeCart() {
      if (this.tempData.items) {
        if (this.tempData.items.length < 1) {
          return;
        }
        try {
          await this.addToCart(this.tempData);
          this.tempData = {};
        } catch (error) {
          // try again
          await this.addToCart(this.tempData);
          this.tempData = {};
        } finally {
          useToastNotification(
            "Sorry",
            "items could not be added to cart. Kindly try again",
          ).error(5000);
        }
      }
    },
  },
});
