<script setup lang="ts">
const isOpen = ref(false)
</script>

<template>
    <UModal>
        <UCard :ui="{ ring: '' }">
            <div class="min-w-full sm:min-w-80">
                <ClientOnly>
                    <UtilitiesCountriesRegion />
                </ClientOnly>
            </div>
        </UCard>
    </UModal>
</template>
