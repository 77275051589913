import type { Customer } from "@medusajs/medusa";
import { defineStore } from "pinia";
import type { StorePostCustomersCustomerReq } from "@medusajs/medusa/dist";

export const useCustomerStore = defineStore("customerStore", {
  state: () => ({
    customer: {} as Omit<Customer, "password_hash">,
  }),
  getters: {
    isLoggedIn: (state) => (state.customer?.id ? true : false),
  },
  actions: {
    async updateCustomer(payload: StorePostCustomersCustomerReq) {
      const { customer: _cus } =
        await useCybandyClient().customers.update(payload);
      try {
        if (_cus) {
          this.customer = _cus;
          return {
            status: true,
            message: "",
          };
        }
      } catch (error: any) {
        return {
          status: false,
          message: error.message,
        };
      }
    },
    async getCustomer() {
      const authStore = useAuthStore();
      const { customer: _cus } = await useCybandyClient(
        authStore.bazariToken,
      ).customers.retrieve();
      if (_cus) {
        this.customer = _cus;

        // check cart_id for customer
        if (this.customer.metadata?.["cart_id"]) {
          await useCartStore().getCart(
            this.customer.metadata["cart_id"] as string,
          );
          await Promise.all([
            useWishlistStore().getAll(),
            useFavouriteStore().fetchUserFav(),
          ]);
        }
        return true;
      }
      return false;
    },
  },
});
