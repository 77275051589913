import type { ICompanyInfo } from "~/types";


export const useCompanyStore = defineStore("companyStore", {
  state: () => ({
    info: {} as ICompanyInfo,
  }),
  persist: {
    storage: persistedState.sessionStorage
  },
  actions: {
    async get() {
      const d = await useCybandyClient().customMethods.company.getInfo();
      if (d) {
        this.info = d;
      }
    },
  },
});
