import type { IProduct, IProductCategory } from "~/types";
import { Product, ProductCollection, ProductCategory } from "@medusajs/medusa";
import type { PricedProduct } from "@medusajs/medusa/dist/types/pricing";

export default defineNuxtPlugin((nuxtApp) => {
  const new_arrivals = ref<PricedProduct | Product[]>([]);
  const featured_products = ref<PricedProduct | Product[]>([]);
  const best_seller = ref<Product[]>([]);
  const collections = ref<ProductCollection[]>([]);
  // const categories = ref<ProductCategory[]>([]);

  // const medusaUrl = useRuntimeConfig().public.medusaBackendUrl;
  // get data for app initialization
  const getData = async () => {
    const data: any =
      await useCybandyClient().customMethods.productInitialization();
    if (data) {
      new_arrivals.value = data.new_arrivals as any;
      featured_products.value = data.featured_products as any;
      best_seller.value = data.best_seller_products as any;
      collections.value = data.collections as any;

      //store
      const categoryStore = storeToRefs(useFilterStore())
      categoryStore.best_sellers.value = best_seller.value as any

    }
  };

  // const getCategories = async () => {
  //   const { product_categories } =
  //     await useCybandyClient().productCategories.list({
  //       include_descendants_tree: true,
  //       parent_category_id: "null",
  //     });
  //   if (product_categories) {
  //     categories.value = product_categories;
  //   }
  // };

  nuxtApp.hook("app:beforeMount", () => {
    useAsyncData("product_initiation_call", async () => {
      await Promise.all([getData(), useFilterStore().getCategories()]);
    });
  });

  return {
    provide: {
      init: {
        new_arrivals: computed(() => new_arrivals.value),
        featured_products: computed(() => featured_products.value),
        best_seller,
        product_collections: computed({
          set: (val) => (collections.value = val),
          get: () => collections.value,
        }),
      },
      products: {
        selectedCategories: ref<string[]>([]),
      },
    },
  };
});
