export default defineNuxtPlugin((nuxtApp) => {
  const customerStore = useCustomerStore();
  const regionStore = useRegionStore();

  nuxtApp.hook("app:beforeMount", () => {
    useAsyncData("initialization", async () => {
      await Promise.all([
        customerStore.getCustomer(),
        regionStore.getCountries(),
      ]);
    });
  });

  return {
    provide: {
      general: {
        headerHeight: ref<number>(0),
      },
    },
  };
});
