<script setup lang="ts">
import type { PropType } from "vue";
import type { PopperOptions } from "#ui/types";

const props = defineProps({
  showLabel: Boolean,
  position: {
    type: String as PropType<"absolute" | "fixed">,
    default: "absolute",
  },
  placement: {
    type: String,
    default: "auto",
  },
  popper: {
    type: Object as PropType<PopperOptions>,
    default: {},
  },
});

const emits = defineEmits(["selected"]);

const regionStore = useRegionStore();
const country = regionStore.activeCountry;

const _key = ref(Date.now());

const nuxtApp = useNuxtApp();

async function changed(val: any) {
  regionStore.chooseCountry(val.iso_2);
  emits("selected");

  _key.value = Date.now();
}
</script>

<template>
  <USelectMenu
    v-if="regionStore.countryList.length > 0"
    @change="changed"
    v-model="country"
    :options="regionStore.countryList"
    :searchable="showLabel"
    :ui-menu="{
      height: 'max-h-60 overflow-y-auto',
      width: 'w-fit',
      base: 'relative focus:outline-none overflow-y-auto scroll-py-1 no-scrollbar ',
    }"
    :popper="popper"
    v-bind="$attrs"
    :key="_key"
  >
    <UButtonGroup size="sm" orientation="horizontal">
      <UButton variant="ghost" icon="i-heroicons-globe-alt" color="black" />

      <UButton color="black" variant="ghost">
        <template #leading>
          <UAvatar
            size="2xs"
            :src="`https://flagcdn.com/${country.iso_2}.svg`"
            :alt="country.name"
            :ui="{ rounded: 'rounded-none', size: { '2xs': 'w-4 h-3' } }"
            crossorigin="anonymous"
          />
        </template>
        <span v-if="showLabel">{{
          country.display_name || "Select country"
        }}</span>
      </UButton>
    </UButtonGroup>

    <template #option="{ option }">
      <!-- <span>{{ option.label }}</span> -->
      <span v-if="showLabel" class="flex items-center gap-4">
        <UAvatar
          size="2xs"
          :src="`https://flagcdn.com/${option.iso_2}`"
          :alt="option.name"
          :ui="{ rounded: 'rounded-none', size: { '2xs': 'w-4 h-3' } }"
          crossorigin="anonymous"
        />
        <span v-if="showLabel">{{ option.name }}</span>
      </span>
      <UTooltip v-else :text="option.name" :popper="{ placement: 'right' }">
        <UAvatar
          size="2xs"
          :src="`https://flagcdn.com/${option.iso_2}.svg`"
          :alt="option.name"
          :ui="{ rounded: 'rounded-none', size: { '2xs': 'w-4 h-3' } }"
          crossorigin="anonymous"
        />
      </UTooltip>
    </template>
  </USelectMenu>
</template>
