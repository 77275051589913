import revive_payload_client_VCWtnEXmYD from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__mc5wldivwe4e3gx4i325yiq2wi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_49fJDG6F2U from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__mc5wldivwe4e3gx4i325yiq2wi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Rc2HttqRVH from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__mc5wldivwe4e3gx4i325yiq2wi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_ch69J9ixge from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@22.2.0_terser@5.3_a37lgtzbghsciqcto42q62voyu/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_tinLs2cV6b from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__mc5wldivwe4e3gx4i325yiq2wi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_l5FjZQwbKg from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__mc5wldivwe4e3gx4i325yiq2wi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_dL6p2fDKjP from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__mc5wldivwe4e3gx4i325yiq2wi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_eCpU2xxTs3 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__mc5wldivwe4e3gx4i325yiq2wi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_59O68PzUXW from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.20.0_typescript@5.5.4_vue@3.4.37_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_Fvz9fKGIGe from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__mc5wldivwe4e3gx4i325yiq2wi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_nTBQRUHQPZ from "/vercel/path0/node_modules/.pnpm/@nuxtjs+web-vitals@0.2.7_magicast@0.3.4_rollup@4.20.0/node_modules/@nuxtjs/web-vitals/dist/runtime/plugin.client.mjs";
import plugin_client_5wH5rZxV1S from "/vercel/path0/node_modules/.pnpm/nuxt-vercel-analytics@1.0.2_magicast@0.3.4_rollup@4.20.0/node_modules/nuxt-vercel-analytics/dist/runtime/plugin.client.mjs";
import plugin_0qmE5hKOOv from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.4_rollup@4.20.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import titles_GLuidY7nFi from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.18_h3@1.12.0_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@22.2.0__yolw62hsqfisouogmuala5mtim/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_B7dTq6dnok from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@22.2.0_terser@_tkfqsor7czf4tuys6onoewurf4/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_fxu66ArXHd from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@22.2.0_terser@_tkfqsor7czf4tuys6onoewurf4/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_yGJPLcpnb3 from "/vercel/path0/node_modules/.pnpm/@nuxt+image@1.7.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.20.0/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import slideovers_dNxEDbOASi from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@0.24.0_focus-trap@7.5.4_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+_c4ejabauxtdsujhe2datihid4u/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_Rp4K875iBk from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@0.24.0_focus-trap@7.5.4_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+_c4ejabauxtdsujhe2datihid4u/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_6vk6pHqjl8 from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@0.24.0_focus-trap@7.5.4_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+_c4ejabauxtdsujhe2datihid4u/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_kMFpnxgd3E from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_magicast@0.3.4_rollup@4.20.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_a9ctBWuZgQ from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.4.5_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@22.2.0_terser@5.31.5__vue@3.4.37_typescript@5.5.4_/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_2tjAJJu7oF from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.20.0_typesc_ni56l37fty6oqqxgtsmogcbup4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _02_product_OktlwvhuiB from "/vercel/path0/plugins/02.product.ts";
import _03_general_46dx8Fy8G7 from "/vercel/path0/plugins/03.general.ts";
import aos_client_Gr50AN4Ul1 from "/vercel/path0/plugins/aos.client.js";
import bugsnag_tkMQfOjcVZ from "/vercel/path0/plugins/bugsnag.ts";
import company_j3fM3zO30U from "/vercel/path0/plugins/company.ts";
import theming_client_dZ2rpcH7wL from "/vercel/path0/plugins/theming.client.ts";
import utilities_Hys3Y2ToiA from "/vercel/path0/plugins/utilities.ts";
import defaults_IJsVkdDvds from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.18_h3@1.12.0_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@22.2.0__yolw62hsqfisouogmuala5mtim/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_VCWtnEXmYD,
  unhead_49fJDG6F2U,
  router_Rc2HttqRVH,
  _0_siteConfig_ch69J9ixge,
  payload_client_tinLs2cV6b,
  navigation_repaint_client_l5FjZQwbKg,
  check_outdated_build_client_dL6p2fDKjP,
  chunk_reload_client_eCpU2xxTs3,
  plugin_vue3_59O68PzUXW,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Fvz9fKGIGe,
  plugin_client_nTBQRUHQPZ,
  plugin_client_5wH5rZxV1S,
  plugin_0qmE5hKOOv,
  titles_GLuidY7nFi,
  siteConfig_B7dTq6dnok,
  inferSeoMetaPlugin_fxu66ArXHd,
  plugin_yGJPLcpnb3,
  slideovers_dNxEDbOASi,
  modals_Rp4K875iBk,
  colors_6vk6pHqjl8,
  plugin_client_kMFpnxgd3E,
  plugin_a9ctBWuZgQ,
  plugin_2tjAJJu7oF,
  _02_product_OktlwvhuiB,
  _03_general_46dx8Fy8G7,
  aos_client_Gr50AN4Ul1,
  bugsnag_tkMQfOjcVZ,
  company_j3fM3zO30U,
  theming_client_dZ2rpcH7wL,
  utilities_Hys3Y2ToiA,
  defaults_IJsVkdDvds
]