export default defineNuxtPlugin((nuxtApp) => {
  // nuxtApp.hook('vue:error',(error:any,instance,info)=>{
  //   useBugsnag().notify(new Error(error))
  // })
  // nuxtApp.vueApp.config.errorHandler = (error:any,instance,info) => {
  //   useBugsnag().notify(new Error(error))
  // }
  // nuxtApp.hook("app:error", (error: any) => {
  //   console.log('this hook worked');

  //   console.log(error);

  //   useBugsnag().notify(new Error(error))
  // })
});
