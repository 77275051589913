export function useToastNotification(
  title = "",
  description = "",
  timeout = 0,
  icon = "",
  color = "",
) {
  const toast = useToast();

  function success(_timeout = 0) {
    const my_title = title ? title : "Success";
    const my_desc = description;
    const my_timeout = _timeout > 0 ? _timeout : timeout;
    const my_icon = icon ? icon : "i-heroicons-check-badge-20-solid";
    const my_color = color ? color : "emerald";

    toast.add({
      title: my_title,
      description: my_desc,
      color: my_color as any,
      timeout: my_timeout,
      icon: my_icon,
    });
  }

  function error(_timeout = 0) {
    const my_title = title ? title : "Oops!!!";
    const my_desc = description ? description : "Something unexpected occurred";
    const my_timeout = _timeout > 0 ? _timeout : timeout;
    const my_icon = icon ? icon : "i-heroicons-exclamation-triangle-20-solid";
    const my_color = color ? color : "red";

    toast.add({
      title: my_title,
      description: my_desc,
      color: my_color as any,
      timeout: my_timeout,
      icon: my_icon,
    });
  }

  function default_toast(_timeout = 0) {
    const my_title = title ? title : "Notification";
    const my_desc = description;
    const my_timeout = _timeout > 0 ? _timeout : timeout;
    const my_icon = icon ? icon : "i-heroicons-bell-alert-solid";
    const my_color = color ? color : "primary";

    toast.add({
      title: my_title,
      description: my_desc,
      color: my_color as any,
      timeout: my_timeout,
      icon: my_icon,
    });
  }

  return { success, error, default_toast };
}

export function useColorHue(name: string) {
  const hash = ref(0);
  const hName = ref(name.split(" ").join(""));
  for (const letter of hName.value) {
    hash.value = letter.charCodeAt(0) + ((hash.value << 5) - hash.value);
    hash.value = hash.value & hash.value;
  }
  hash.value = hash.value % 360;
  return hash.value;
}

export const useBackgroundColor = (input_text: string) => {
  // const { $isDark } = useNuxtApp();
  if (process.server) return;
  const isDark = useNuxtApp().$theme.isDark;
  const name = ref(input_text);
  let a = 1;
  // if(showOverlay.value){a = 0.6}
  const col = useColorHue(name.value || "Cybandy");

  const res = isDark.value
    ? { backgroundColor: `hsla(${col}, 70%, 65%, ${a})` }
    : { backgroundColor: `hsla(${col}, 90%, 25%, ${a})` };

  return res;
};

export function useOnlyUnique(value: any, index: number, array: Array<any>) {
  return array.indexOf(value) === index;
}
