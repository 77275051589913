import { ModalRegionSelector } from '#components'
export default defineNuxtPlugin((nuxtApp) => {
  const isDark = useDark();
  const toggleDark = useToggle();
  const initModal = ref(false);
  const modal = useModal()

  // change primary color based on dark/light mode
  function changePrimary() {
    useAppConfig().ui.primary = isDark.value ? "turquoise" : "teak";
  }
  // hook into page setup
  nuxtApp.hook("app:created", () => {
    changePrimary();
  });

  nuxtApp.hook("app:mounted", () => {
    modal.open(ModalRegionSelector)
  })

  // watch changes of isDark and toggle
  watch(isDark, () => changePrimary());

  return {
    provide: {
      theme: {
        toggleDark: toggleDark,
        isDark: isDark,
      },
      setup: {
        initModal,
      },
    },
  };
});
