import type {
  Cart,
  Item,
  StorePostCartReq,
  StorePostCartsCartReq,
} from "@medusajs/medusa";
import { groupBy } from "lodash-es";
export const useCheckoutStore = defineStore("checkoutStore", {
  state: () => ({
    checkoutCart: {} as Cart,
    temp: {} as StorePostCartReq,
  }),
  persist: {
    storage: persistedState.localStorage,
  },
  getters: {
    groupedById: (state) => groupBy(state.checkoutCart.items, (item) => item.variant.product_id)
  },
  actions: {
    async initializeCheckout(payload: StorePostCartReq, fromCart = false) {
      const { customer, isLoggedIn } = storeToRefs(useCustomerStore());
      const regionStore = useRegionStore();
      payload.region_id = regionStore.activeCountry.region_id as string;
      if (!isLoggedIn.value) {
        this.temp = payload;
        return navigateTo("/auth");
      }
      const { cart: _cart } = await useCybandyClient().carts.create(payload);
      if (_cart) {
        this.checkoutCart = _cart;
      }

      const { shipping_options } =
        await useCybandyClient().shippingOptions.list({
          region_id: regionStore.activeCountry.region_id as string,
        });

      if (shipping_options.length > 0) {
        await useCybandyClient().carts.addShippingMethod(_cart.id, {
          option_id: shipping_options[0].id as string,
        });
      }

      const updatePayload: StorePostCartsCartReq = {
        customer_id: customer.value.id,
      };

      if (customer.value.billing_address_id) {
        updatePayload.billing_address = {
          first_name: customer.value.billing_address.first_name as string,
          last_name: customer.value.billing_address.last_name as string,
          address_1: customer.value.billing_address.address_1 as string,
          address_2: customer.value.billing_address.address_2 as string,
          country_code: customer.value.billing_address.country_code as string,
          postal_code: customer.value.billing_address.postal_code as string,
          phone: customer.value.billing_address.phone as string,
          city: customer.value.billing_address.city as string,
        };
      }

      if (customer.value.shipping_addresses.length > 0) {
        updatePayload.shipping_address = {
          first_name: customer.value.shipping_addresses[0].first_name as string,
          last_name: customer.value.shipping_addresses[0].last_name as string,
          address_1: customer.value.shipping_addresses[0].address_1 as string,
          address_2: customer.value.shipping_addresses[0].address_2 as string,
          country_code: customer.value.shipping_addresses[0]
            .country_code as string,
          postal_code: customer.value.shipping_addresses[0]
            .postal_code as string,
          phone: customer.value.shipping_addresses[0].phone as string,
          city: customer.value.shipping_addresses[0].city as string,
        };
      }

      const { cart } = await useCybandyClient().carts.update(
        this.checkoutCart.id,
        { customer_id: customer.value.id },
      );
      if (cart) {
        this.checkoutCart = cart;
      }

      vercelTrack("Initialized checkout cart", {
        customer: customer.value.id,
        cart: cart.id,
        region_id: regionStore.activeRegion.id,
        regionCountry: regionStore.activeCountry.display_name
      })
    },

    async initTempData() {
      if (this.temp.items) {
        if (this.temp.items.length > 0) {
          await this.initializeCheckout(this.temp);
          return navigateTo("/checkout", { replace: true });
        }
      }
      // return navigateTo('/')
    },

    async getCart(id = "") {
      const id_ = id ? id : this.checkoutCart.id;
      if (id_) {
        const { cart } = await useCybandyClient().carts.retrieve(id_);
        if (cart) {
          this.checkoutCart = cart;
        }
      }
    },
    async updateCart(payload: StorePostCartsCartReq) {
      const { cart } = await useCybandyClient().carts.update(
        this.checkoutCart.id,
        payload,
      );
      if (cart) {
        this.checkoutCart = cart;
      }
    },

    async initPaymentIntent() {
      // check if session exist then we refresh it
      const { customer, isLoggedIn } = storeToRefs(useCustomerStore());
      const regionStore = useRegionStore();
      if (this.checkoutCart.payment_session) {
        const { cart } = await useCybandyClient().carts.refreshPaymentSession(
          this.checkoutCart.id,
          this.checkoutCart.payment_session.id,
        );
        this.checkoutCart = cart;



        return;
      }

      // create a session
      const { cart } = await useCybandyClient().carts.createPaymentSessions(
        this.checkoutCart.id,
      );
      this.checkoutCart = cart;

      // since stripe is the default payment provider
      // we automatically search and provide it
      const isStripeAvailable = this.checkoutCart.payment_sessions?.some(
        (session) => session.provider_id === "stripe",
      );

      if (isStripeAvailable) {
        const { cart: cart_ } =
          await useCybandyClient().carts.setPaymentSession(
            this.checkoutCart.id,
            { provider_id: "stripe" },
          );

        this.checkoutCart = cart_;
      }
    },

    async completePayment() {
      const { customer, isLoggedIn } = storeToRefs(useCustomerStore());
      const regionStore = useRegionStore();
      try {
        await useCybandyClient().carts.complete(this.checkoutCart.id);

        vercelTrack("completed payment", {
          customer: customer.value.id,
          cart: this.checkoutCart.id,
          region_id: regionStore.activeRegion.id,
          regionCountry: regionStore.activeCountry.display_name
        })
        return true;
      } catch (error) {
        return false;
      }
    },

    amountConstraint(amt: number) {
      return amt >= 500 * 100
    }
  },
});
