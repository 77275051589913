import type { Product, ProductCategory } from "@medusajs/medusa";
import type { PricedProduct } from "@medusajs/medusa/dist/types/pricing";
import { groupBy, sortBy } from "lodash-es";

export const useFilterStore = defineStore("filterStore", {
    state: () => ({
        categories: [] as ProductCategory[],
        best_sellers: [] as Array<Product | PricedProduct>
    }),
    getters: {
        getChildren: (state) => (query = { id: '', handle: '' }) => {
            if (!query.id && !query.handle) { return [] }

            let cat = (state.categories.find((c) => c.id === query.id || c.handle === query.handle))

            return sortBy(cat?.category_children, 'name') ?? []
        },

        getCategory: (state) => (handle: string) => state.categories.find((c) => c.handle == handle),
        getCategoryById: (state) => (id: string) => state.categories.find((c) => c.id == id),

        noParent: (state) => state.categories.filter((x) => x.parent_category_id == null)
    },
    actions: {
        async getCategories() {
            const { product_categories } = await useCybandyClient().productCategories.list({
                include_descendants_tree: true,
            });

            if (product_categories) {
                this.categories = product_categories;
            }
        }
    },

})
