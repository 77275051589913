import Medusa from "@medusajs/medusa-js";
import { useAuthStore } from "@@/stores/authStore";
import type { Config } from "@medusajs/medusa-js";
// import type { UseFetchOptions } from "#app";
import type {
  Wishlist,
  wishlistPostReqParams,
  ListResult,
  Favourite,
  ICompanyInfo,
  IFAQSECTION,
} from "~/types";

class cybandyMedusa extends Medusa {
  protected _config: Config;

  constructor(conf: Config) {
    super(conf);
    this._config = conf;
  }

  async cybandyFetch(
    path: string,
    method: any,
    body: any = null,
    query: any = null,
  ) {
    return await $fetch(`${this._config.baseUrl}${path}`, {
      headers: this._config.customHeaders,
      method: method,
      body,
      query,
    });
  }

  customMethods = {
    sendContactMessage: async (payload: any) => {
      return await this.cybandyFetch("/store/contact", "POST", payload, null);
    },
    productInitialization: async () => {
      return await this.cybandyFetch("/store/initialization/products", "GET");
    },
    verifyAccountInvite: async (id: string, email: string) => {
      return await this.cybandyFetch('/store/account_request', 'get', null, { id, email }) as boolean
    },
    confirmAccountInvite: async (payload: any) => {
      return await this.cybandyFetch(
        "/store/account_request",
        "PATCH",
        payload,
      );
    },
    emailExist: async (email: string) => {
      return await this.cybandyFetch(
        "/store/account_request/email-exist",
        "GET",
        null,
        { email },
      );
    },
    requestAccount: async (payload: any) => {
      return await this.cybandyFetch("/store/account_request", "POST", payload);
    },
    wishlist: {
      list: async (query: any) => {
        return (await this.cybandyFetch(
          "/store/wishlist",
          "get",
          null,
          query,
        )) as ListResult<Wishlist, "wishlist">;
      },
      update: async (id: string, body: wishlistPostReqParams) => {
        return (await this.cybandyFetch("/store/wishlist", "patch", body, {
          id,
        })) as Wishlist;
      },
      create: async (body: wishlistPostReqParams) => {
        return (await this.cybandyFetch(
          "/store/wishlist",
          "post",
          body,
          null,
        )) as Wishlist;
      },
      delete: async (id: string) => {
        return (await this.cybandyFetch("/store/wishlist", "delete", null, {
          id,
        })) as { deleted: boolean; id: string };
      },
      retrieve: async (id: string, query?: any) => {
        return (await this.cybandyFetch(
          `/store/wishlist/${id}`,
          "get",
          null,
          query,
        )) as Wishlist;
      },

      addProducts: async (id: string, product_ids: string[]) => {
        return await this.cybandyFetch(
          "/store/wishlist/product",
          "post",
          { product_ids },
          { id },
        );
      },
      removeProducts: async (id: string, product_ids: string[]) => {
        return await this.cybandyFetch(
          "/store/wishlist/product",
          "delete",
          { product_ids },
          { id },
        ) as Wishlist;
      },
    },
    favourite: {
      likeDislike: async (product_id: string) => {
        return (await this.cybandyFetch("/store/favourite", "post", null, {
          product_id,
        })) as Favourite | { deleted: boolean; id: string };
      },
      count: async (product_id: string) => {
        return (await this.cybandyFetch(
          "/store/favourite/product",
          "get",
          null,
          { product_id },
        )) as number;
      },
      list: async (query: any) => {
        return (await this.cybandyFetch(
          "/store/favourite",
          "get",
          null,
          query,
        )) as { favourite: Favourite[]; count: number };
      },
    },
    reviews: {
      list: async (
        product_id: string,
        expand?: string,
        field?: string,
        limit?: number,
        offset?: number,
      ) => {
        return await this.cybandyFetch("/store/review", "get", null, {
          product_id,
          expand,
          field,
          limit,
          offset,
        });
      },
      create: async (
        product_id: string,
        rating: number,
        message: string = "",
      ) => {
        return await this.cybandyFetch(
          "/store/review",
          "post",
          { product_id, rating, message },
          null,
        );
      },
    },

    removeFromCustomerCartAfterPurchase: async (checkout_id: string) => {
      await this.cybandyFetch("/cybandy/store_/cart/complete", "delete", null, {
        id: checkout_id,
      });
    },
    company: {
      getInfo: async () => {
        return (await this.cybandyFetch(
          "/cybandy/store_/shop",
          "get",
        )) as ICompanyInfo;
      },
    },
    sitemap: {
      dynamicUrls: async () => {
        return await this.cybandyFetch('/store/sitemap', 'get') as string[]
      }
    },
    faq: {
      sectionList: async (query = {}) => {
        return await this.cybandyFetch('/store/faq', 'get', null, query) as { faq_section: IFAQSECTION[], limit: number, offset: number, count: number }
      }
    }
  };
}

export function useCybandyClient(_token = "") {
  const payload = {} as Config;
  const authStore = storeToRefs(useAuthStore());
  const bazariToken = authStore.bazariToken;
  const token = computed(() => (_token ? _token : bazariToken.value));
  // // console.log('useCybandyClient: token', bazariToken.value);

  payload.baseUrl = useRuntimeConfig().public.medusaBackendUrl;
  payload.maxRetries = 3;

  if (token.value) {
    payload.customHeaders = {
      Authorization: `Bearer ${bazariToken.value}`,
    };
  }

  const cybandy = new cybandyMedusa(payload);

  return cybandy;
}
