<script setup lang="ts">
useHead({
  link: [
    {
      rel: "icon",
      type: "image/svg+xml",
      href: "/favicon.ico",
    },
  ],
});

//seo
useSchemaOrg([
  defineWebSite({
    potentialAction: [
      defineSearchAction({
        target: '/search/q={search_term_string}'
      })
    ]
  })
])

const { $script } = useScript({
  src: 'https://cdn-cookieyes.com/client_data/fb91582280e89fa82a6da9c6/script.js',
  crossorigin: 'anonymous',
  async: true,
  defer: true
}, {
  trigger: 'manual'
})

const { currencyCode } = storeToRefs(useRegionStore())

onMounted(() => {
  if (process.env.NODE_ENV === 'production') {
    setTimeout(async () => {
      await $script.load()
    }, 10000)
  }

})

const { activeRegion } = storeToRefs(useRegionStore())
watch(activeRegion, () => {
  reloadNuxtApp()
})

</script>

<template>
  <!-- <main> -->
  <NuxtLayout>
    <NuxtLoadingIndicator />
    <NuxtPage />
    <UNotifications />
  </NuxtLayout>
  <!-- </main> -->
</template>
